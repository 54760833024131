import React from 'react';
import './Home.css';
import { GoogleSpreadsheet } from "google-spreadsheet";

//const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"

const SPREADSHEET_ID = "1jsMkRwrqKNoL1gNX1vjxUFCTMJ8gCTHBVSOSTHdxqf0";
const CLIENT_EMAIL = "dianamilena35mm-admin@dianamilena35mm.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2fcbAiD4SXvqu\n5dP5GVq73GvROBGHQ2rK7n+PoLjrHJMinNClugMbcBjKftHR+CZxYZ1PTYQk+Ia6\n/SxPZ9FBg0K65IeReeqUyy+aQqUIfO8UnAfgxTG8MlrdXQbn/+Dp6w9aktWG9lxD\nb6DGhqUZUOB6GdH3LRjTKMq0g8eVYeVtkhKoudf3eMfiVCbMDgan5lPEPa0Ov/Bb\nLaaYRUV0cULLZ1yDbkFpcxkSjrz77ox044KjECkPHfVRpKGsMuIcMb1bc3uO9nco\nk3o1FP2B4gTvBNAP+MJBWzsQKAGCFCWgMMCjR/KsSQcgUddVpJbUel6wyxfQQE8Q\nkXYq9FpBAgMBAAECggEAI5fzoJDSPoYM8fj8t6y00EhlNRjw/kdteGg9NBfeiWj4\n37rjTTezLHJYO4KxzPjQErQ92P5fBFD/AlCoCFLQ8yy1Yrt5H6w+T0g0ICvYgYfQ\nn93xAWuk0DaoF13yH2T+itFgnMNf52N8ymBm5OHVKNNn/Ri1JbdDjMf7hWrgjieG\nJdeHAwsskQBiupPEB8uIFSlThjhKMbQRPjkG+oD9zUmOpAMkxIH3J3qCudsf64rm\nanjPiQeCyh0xhfHBZax46bEVJI2G+U/3Z+dJtZYjXx0RgR8IPBQwvVVTO426qVmk\n4/vfieIKDkqtKUFWBFEXXYYVFKGjMAh1IhvH+GqlmwKBgQDY0qousS0GW/kl85mw\n/rCFKNOjNr/rem97Xjs9z5gIKeVIJCmy4JdTZf30R+n8ud9IjCWUxmFPYoJQIK5L\nXpWQaAAK7T47eGKbJ0+N/QRZqSyvSBS3+Hq/SyMYfRuJCAVoIOY1VJoyCFKAjCdq\niZ5F6m1EjSd9lpqZ6yJGPpXGJwKBgQDXdxLFpOSyXHadmOvQw+5dyKAZakbc4wbT\nz9mPskv11oLO2h1fdi8RJxU/nvTx8+BXid4ffjui6rknItt+W/8uZG9nmHE96I5M\nJA8bt2mDKrOesCt1SXZI3ey9m9n8vvxXGsxr2WWVxncsKqGpnfg0AJ+Cm5a1W98F\ny5ArtB7FVwKBgQDYYFOJSTL/l6Vp5FJKOcD7AiVCSg749jRuzzkblCHKc9TmS4B5\njHw3tB7dOy8dEHjtMz8F23uc91fIkvSdLoTfmYoBXYSvYJI29+ICdq9ysstvGOXk\nOkp0wI0DkUZ7V8/dfIeATS+cIoYC1ts3DKMwbqQgtyTjxfn/BJItu6NIHQKBgEwf\ng4BUe4AQbs/LITVGo8Z6hSW1iIetpdHbky/TfEbEnL5cDRiW9pYGytkJXdzFnrMO\nwG07+fHbH8MV65o/Am7viPR+xTNsICoCa1wNdreitIcSEqiDZcjkrylmEKPdDeXw\nT9yil+NMlPoDrc3xQ6mLts88DQjSBn9TpgHqxxxNAoGAYaZcWFvAbvgVBk4W6tNU\nwdYwc++2u7FxuW/DWWRMohdt/YSuZqVZGK69kR/kgcnt40FxlzpAa9eiv+jcFdsJ\ncpTr2B39I3gioh7LMIv0Ubjv4nf7eoCduisbdi/VDMPCELBvEaKhfobfIILeT7dn\nJQ9Swbh3zKjT7qRM2+F20Pk=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

class Message extends React.Component { 

  constructor() {
    super();
    this.state = { 
     mobile: false,
     imageArray: [],
     showModal: false,
     activeImg: null,
     currentIndex: null,
     name: '',
     message: '',
     
     
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    
  }

  submitMessage() {

    var message = document.querySelector('#message').value
    var name = document.querySelector('#name').value
    if(message === '' || name === '')
    {
      alert("you have to fill out both fields!")
    }
    else
    {
      doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
  
      // loads document properties and worksheets-
      doc.loadInfo();
      
      var sheet = doc.sheetsById["1450275671"];
      sheet.addRows([
        { Name: name,  Message: message}])
         
      document.querySelector('#message').value = ""
      document.querySelector('#name').value = ""
      
      alert("thanks for submitting!")

      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }

  }


  handleOpenModal (image) {


    this.setState({ showModal: true, activeImg: image.target.src, currentIndex: image.target.id });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  componentDidMount()
  {

    document.body.style.overflow = "scroll"

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});
    }  
    
     const sheetsToData = async () => {
      try {
        await doc.useServiceAccountAuth({
          //key: "AIzaSyCZlmooivp6d9prrTllhZNlGfzjYeq0tx4"
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });

        // loads document properties and worksheets-
        await doc.loadInfo();

        var sheet = doc.sheetsById["1794120329"];
        var row = sheet.getRows();
        
        var tempArray = []
        //rows for pages
        row.then((value) => {
          for(var i = 0; i < value.length; i++)
          {
            tempArray.push(String(value[i]['_rawData'][0]))
          }
          this.setState({imageArray: tempArray})
        })//.then((finalArray) => {this.setState({one: finalArray[0], two: finalArray[1], three: finalArray[2], four: finalArray[3], five: finalArray[4]})});
        
      } catch (e) {
          //error if google sheets quota reached
          alert("hmmm... the site failed to load, i'll reload automatically, exit this alert!" );
          
          setTimeout(() => {
            window.location.reload()
          }, 5000);
      }
    };

    sheetsToData()
   
  }

  

  

  
  render()
  {  
    return (
      <div className="secondaryPageContainer">
        <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/meowBackButton.webp" alt="back button" className='backButton' onClick={(e) => {window.location.href = "/"}}></img>
      
        <div className='centerMe'>
          <div className='formContainer'>
            <h1 className='pageHeaderText'>Leave a message...</h1>
            <div className='spacing'></div>


            <div className="messageContainer">
              <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/image.webp" className='chatBox' alt="chatbox"></img>
              <textarea className='text-block' id="message" placeholder='message'></textarea>
            </div>
        
            <div className='spacing'></div>
            <div className='messageContainer'>
              <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/namebox.webp" className='nameBox' alt="namebox"></img>
              <div className='nameDiv'>
                <input className='name-block' id="name" placeholder='name'></input>
              </div>
            </div>
          
            
            <div className='spacing'></div>
            <h2 className='submitText' onClick={this.submitMessage}>Submit</h2>
          </div>
        </div>
       



       
       </div>
 

    )
    
    
          
      
  }
}
export default Message;