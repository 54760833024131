import react from "react";
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  

export default function ChildComponent({ data, setClick, goInner }) {
    const [modalIsOpen, setIsOpen] = react.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    return (
    <div
      className="childComponent"
      
    >
        <img src={data} className='bubblePic' loading="lazy" alt="img" onClick={openModal}
        /*setClick(data);
        goInner(true);*/

      ></img>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        
      >
          
           <img src={data} alt="img" className='modalPic' onClick={openModal}></img>
           
      </Modal>
        
      {/*{data}*/}
    </div>
  );
}
