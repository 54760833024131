export const subData = {
    one: "one",
    two: "two",
    three: "three",
    four: "four",
    five: "five",
    six: "six",
    seven: "seven",
    eight: "eight",
    nine: "nine",
    ten: "ten",
    eleven: "eleven",
    twelve: "twelve",
    thirteen: "thirteen",
    fourteen: "fourteen",
    fifteen: "fifteen",
    sixteen: "sixteen",
    seventeen: "seventeen",
    eighteen: "eighteen",
    ninety: "nine",
    twenty: "twenty"
  };
  