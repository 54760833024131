import './Home.css';


// myComponent.js

import { useEffect, useState } from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import Child from "./ChildComponent";
import { subData } from './subdata';

import { GoogleSpreadsheet } from "google-spreadsheet";
import react from "react";
import Modal from 'react-modal';

//const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"

const SPREADSHEET_ID = "1jsMkRwrqKNoL1gNX1vjxUFCTMJ8gCTHBVSOSTHdxqf0";
const CLIENT_EMAIL = "dianamilena35mm-admin@dianamilena35mm.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2fcbAiD4SXvqu\n5dP5GVq73GvROBGHQ2rK7n+PoLjrHJMinNClugMbcBjKftHR+CZxYZ1PTYQk+Ia6\n/SxPZ9FBg0K65IeReeqUyy+aQqUIfO8UnAfgxTG8MlrdXQbn/+Dp6w9aktWG9lxD\nb6DGhqUZUOB6GdH3LRjTKMq0g8eVYeVtkhKoudf3eMfiVCbMDgan5lPEPa0Ov/Bb\nLaaYRUV0cULLZ1yDbkFpcxkSjrz77ox044KjECkPHfVRpKGsMuIcMb1bc3uO9nco\nk3o1FP2B4gTvBNAP+MJBWzsQKAGCFCWgMMCjR/KsSQcgUddVpJbUel6wyxfQQE8Q\nkXYq9FpBAgMBAAECggEAI5fzoJDSPoYM8fj8t6y00EhlNRjw/kdteGg9NBfeiWj4\n37rjTTezLHJYO4KxzPjQErQ92P5fBFD/AlCoCFLQ8yy1Yrt5H6w+T0g0ICvYgYfQ\nn93xAWuk0DaoF13yH2T+itFgnMNf52N8ymBm5OHVKNNn/Ri1JbdDjMf7hWrgjieG\nJdeHAwsskQBiupPEB8uIFSlThjhKMbQRPjkG+oD9zUmOpAMkxIH3J3qCudsf64rm\nanjPiQeCyh0xhfHBZax46bEVJI2G+U/3Z+dJtZYjXx0RgR8IPBQwvVVTO426qVmk\n4/vfieIKDkqtKUFWBFEXXYYVFKGjMAh1IhvH+GqlmwKBgQDY0qousS0GW/kl85mw\n/rCFKNOjNr/rem97Xjs9z5gIKeVIJCmy4JdTZf30R+n8ud9IjCWUxmFPYoJQIK5L\nXpWQaAAK7T47eGKbJ0+N/QRZqSyvSBS3+Hq/SyMYfRuJCAVoIOY1VJoyCFKAjCdq\niZ5F6m1EjSd9lpqZ6yJGPpXGJwKBgQDXdxLFpOSyXHadmOvQw+5dyKAZakbc4wbT\nz9mPskv11oLO2h1fdi8RJxU/nvTx8+BXid4ffjui6rknItt+W/8uZG9nmHE96I5M\nJA8bt2mDKrOesCt1SXZI3ey9m9n8vvxXGsxr2WWVxncsKqGpnfg0AJ+Cm5a1W98F\ny5ArtB7FVwKBgQDYYFOJSTL/l6Vp5FJKOcD7AiVCSg749jRuzzkblCHKc9TmS4B5\njHw3tB7dOy8dEHjtMz8F23uc91fIkvSdLoTfmYoBXYSvYJI29+ICdq9ysstvGOXk\nOkp0wI0DkUZ7V8/dfIeATS+cIoYC1ts3DKMwbqQgtyTjxfn/BJItu6NIHQKBgEwf\ng4BUe4AQbs/LITVGo8Z6hSW1iIetpdHbky/TfEbEnL5cDRiW9pYGytkJXdzFnrMO\nwG07+fHbH8MV65o/Am7viPR+xTNsICoCa1wNdreitIcSEqiDZcjkrylmEKPdDeXw\nT9yil+NMlPoDrc3xQ6mLts88DQjSBn9TpgHqxxxNAoGAYaZcWFvAbvgVBk4W6tNU\nwdYwc++2u7FxuW/DWWRMohdt/YSuZqVZGK69kR/kgcnt40FxlzpAa9eiv+jcFdsJ\ncpTr2B39I3gioh7LMIv0Ubjv4nf7eoCduisbdi/VDMPCELBvEaKhfobfIILeT7dn\nJQ9Swbh3zKjT7qRM2+F20Pk=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const cachedData = JSON.parse(localStorage.getItem('cachedData')) || {};




export default function Home(props) {
  const [bubble, setBubble] = useState("");
  const [backToInterest, setBackToInterest] = useState(false);

  const [myArray, updateMyArray] = useState([]);

  const [modalIsOpen, setIsOpen] = react.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
 
    document.body.style.overflow = "hidden"
    // declare the data fetching function
    const sheetsToData = async () => {
      try {
        // Check if data exists in cache
      /*  if (cachedData.myArray) {
          updateMyArray(cachedData.myArray);
          return;
        }*/
    
        // If data does not exist in cache, make an API request
        await doc.useServiceAccountAuth({
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });
    
        await doc.loadInfo();
    
        var sheet = doc.sheetsById["0"];
        var row = sheet.getRows();
    
        row.then((value) => {
          const data = value.map((item) => String(item['_rawData'][0]));
          updateMyArray(data);
          // Save data to cache
          cachedData.myArray = data;
          localStorage.setItem('cachedData', JSON.stringify(cachedData));
        });
      } catch (error) {
        alert("hmmm... the site failed to load, i'll reload automatically, exit this alert!");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    };
    

    sheetsToData()
    

    
      
      
  }, [])
  

  const options = {
    size: 180,
		minSize: 25,
		gutter: 50,
		provideProps: true,
		numCols: 6,
		fringeWidth: 156,
		yRadius: 130,
		xRadius: 220,
		cornerRadius: 50,
		showGuides: false,
		compact: true,
		gravitation: 0
  };

  const handleClick = (bub) => {
    setBubble(bub);
  };

  const children = myArray?.map((data, i) => {
    return (
      <Child
        data={!backToInterest ? data : subData[bubble]}
        className="child"
        key={i}
        setClick={handleClick}
        goInner={setBackToInterest}
      />
    );
  });

  return (
    <>
      <div className='pageContainer' onClick={
        (e) => {
          console.log(e.target.className)
          if((e.target.className === "navigationContainer") || (e.target.className === "ReactModal__Overlay ReactModal__Overlay--after-open") || (e.target.className === "ReactModal__Content ReactModal__Content--after-open"))
          {
            
            closeModal()
          }
          
        }} >



        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          
        >
          <div className='centerMe'>
          <center>
            <div className='navigationContainer'>
              <div className='navigationCard' onClick={(e) => {window.location.href = "/message"}}>
                <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/message.webp" className='navigationCardImage' alt="message"></img>
                <h1 className='navText'>Message</h1>
              </div>
              <div className='navigationCard' onClick={(e) => {window.location.href = "/portfolio"}}>
                <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/resume.webp" className='navigationCardImage' alt="portfolio"></img>
                <h1 className='navText'>Portfolio</h1>
              </div>
              <div className='navigationCard' onClick={(e) => {window.location.href = "/blog"}}>
                <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/blog.webp" className='navigationCardImage' alt="blog"></img>
                <h1 className='navText'>Blog</h1>
              </div>
              <div className='navigationCard' onClick={(e) => {window.location.href = "/gallery"}}>
                <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/gallery.webp" className='navigationCardImage' alt="gallery"></img>
                <h1 className='navText'>Gallery</h1>
              </div>
            </div>
          </center>
          </div>
            
        </Modal>
        
        
      
   
      <BubbleUI
        options={options}
        className={!backToInterest ? "myBubbleUI" : "interestBubbleUI"}
      >
        {children}
      </BubbleUI>
      <img loading='lazy' src='https://dianamilena35mm.b-cdn.net/dianamilena35mm_logo-min.png' className='logo' onClick={
        (e) => {openModal()}} alt="logo"></img>
      <img loading='lazy' src='https://dianamilena35mm.b-cdn.net/general/clickMe.png' className='clickMe' onClick={
        (e) => {openModal()}} alt="click me"></img>
      <div className='footer'>
        <center>
        
        <a href='https://www.instagram.com/dianamilena35mm/?hl=en' target='_blank' rel="noreferrer"><img src='https://dianamilena35mm.b-cdn.net/ig%20logo-min.png' className='instaFooter' alt="logo"></img></a>
        </center>
        </div>
        
  </div>
    </>
  );
}
