import React from 'react';
import './Home.css';
import { GoogleSpreadsheet } from "google-spreadsheet";
import ReactModal from 'react-modal';

//const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"

const SPREADSHEET_ID = "1jsMkRwrqKNoL1gNX1vjxUFCTMJ8gCTHBVSOSTHdxqf0";
const CLIENT_EMAIL = "dianamilena35mm-admin@dianamilena35mm.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2fcbAiD4SXvqu\n5dP5GVq73GvROBGHQ2rK7n+PoLjrHJMinNClugMbcBjKftHR+CZxYZ1PTYQk+Ia6\n/SxPZ9FBg0K65IeReeqUyy+aQqUIfO8UnAfgxTG8MlrdXQbn/+Dp6w9aktWG9lxD\nb6DGhqUZUOB6GdH3LRjTKMq0g8eVYeVtkhKoudf3eMfiVCbMDgan5lPEPa0Ov/Bb\nLaaYRUV0cULLZ1yDbkFpcxkSjrz77ox044KjECkPHfVRpKGsMuIcMb1bc3uO9nco\nk3o1FP2B4gTvBNAP+MJBWzsQKAGCFCWgMMCjR/KsSQcgUddVpJbUel6wyxfQQE8Q\nkXYq9FpBAgMBAAECggEAI5fzoJDSPoYM8fj8t6y00EhlNRjw/kdteGg9NBfeiWj4\n37rjTTezLHJYO4KxzPjQErQ92P5fBFD/AlCoCFLQ8yy1Yrt5H6w+T0g0ICvYgYfQ\nn93xAWuk0DaoF13yH2T+itFgnMNf52N8ymBm5OHVKNNn/Ri1JbdDjMf7hWrgjieG\nJdeHAwsskQBiupPEB8uIFSlThjhKMbQRPjkG+oD9zUmOpAMkxIH3J3qCudsf64rm\nanjPiQeCyh0xhfHBZax46bEVJI2G+U/3Z+dJtZYjXx0RgR8IPBQwvVVTO426qVmk\n4/vfieIKDkqtKUFWBFEXXYYVFKGjMAh1IhvH+GqlmwKBgQDY0qousS0GW/kl85mw\n/rCFKNOjNr/rem97Xjs9z5gIKeVIJCmy4JdTZf30R+n8ud9IjCWUxmFPYoJQIK5L\nXpWQaAAK7T47eGKbJ0+N/QRZqSyvSBS3+Hq/SyMYfRuJCAVoIOY1VJoyCFKAjCdq\niZ5F6m1EjSd9lpqZ6yJGPpXGJwKBgQDXdxLFpOSyXHadmOvQw+5dyKAZakbc4wbT\nz9mPskv11oLO2h1fdi8RJxU/nvTx8+BXid4ffjui6rknItt+W/8uZG9nmHE96I5M\nJA8bt2mDKrOesCt1SXZI3ey9m9n8vvxXGsxr2WWVxncsKqGpnfg0AJ+Cm5a1W98F\ny5ArtB7FVwKBgQDYYFOJSTL/l6Vp5FJKOcD7AiVCSg749jRuzzkblCHKc9TmS4B5\njHw3tB7dOy8dEHjtMz8F23uc91fIkvSdLoTfmYoBXYSvYJI29+ICdq9ysstvGOXk\nOkp0wI0DkUZ7V8/dfIeATS+cIoYC1ts3DKMwbqQgtyTjxfn/BJItu6NIHQKBgEwf\ng4BUe4AQbs/LITVGo8Z6hSW1iIetpdHbky/TfEbEnL5cDRiW9pYGytkJXdzFnrMO\nwG07+fHbH8MV65o/Am7viPR+xTNsICoCa1wNdreitIcSEqiDZcjkrylmEKPdDeXw\nT9yil+NMlPoDrc3xQ6mLts88DQjSBn9TpgHqxxxNAoGAYaZcWFvAbvgVBk4W6tNU\nwdYwc++2u7FxuW/DWWRMohdt/YSuZqVZGK69kR/kgcnt40FxlzpAa9eiv+jcFdsJ\ncpTr2B39I3gioh7LMIv0Ubjv4nf7eoCduisbdi/VDMPCELBvEaKhfobfIILeT7dn\nJQ9Swbh3zKjT7qRM2+F20Pk=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];



class Blog extends React.Component { 

  constructor() {
    super();
    this.state = { 
     showModal: false,
     matrix: [],
     currentStory: {images: []},
     showPage: false,
     questionOne: null,
     questionTwo: null,
     questionThree: null,
     answerOne: null,
     answerTwo: null,
     answerThree: null
     
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    
  }

  handleOpenModal (story) {
 
    var hideMe = document.querySelector("#hideMe");
    hideMe.style.zIndex = '-1';
    
    this.setState({ showModal: true, currentStory: story});
  }
  
  handleCloseModal () {
    var hideMe = document.querySelector("#hideMe");
    hideMe.style.zIndex = '999';
    this.setState({ showModal: false });
  }

  submitMessage() {
    

    var firstAnswer = String(document.querySelector('#firstAnswer').value).toLowerCase()
    var secondAnswer = String(document.querySelector('#secondAnswer').value).toLowerCase()
    //var thirdAnswer = String(document.querySelector('#thirdAnswer').value).toLowerCase()
    if(firstAnswer === '' || secondAnswer === '')
    {
      alert("you have to fill out the answers to access this page!")
    }
    else
    {
      if(firstAnswer === this.state.answerOne && secondAnswer === this.state.answerTwo)
      {
        sessionStorage.setItem('valid', true);
        this.setState({showPage: Boolean(sessionStorage.getItem("valid"))})
      }
      else
      {
        alert("your answers were incorrect :(\ntry again")
        document.querySelector('#firstAnswer').value = ""
        document.querySelector('#secondAnswer').value = ""
       // firstAnswer.value = "";
        //secondAnswer.value = "";
        //thirdAnswer.value = "";
      }
    }

  }

  componentDidMount()
  {
    
    if(sessionStorage.getItem("valid"))
    {
      //update
      //console.log(Boolean(sessionStorage.getItem("valid")))
      this.setState({showPage: Boolean(sessionStorage.getItem("valid"))})
    }

    document.body.style.overflow = "scroll"

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});
    }  

    const cachedData = localStorage.getItem("questionsData");
    const cachedData2 = localStorage.getItem("blogData");

   /* if (cachedData && cachedData2) {
      this.setState({ questionOne: JSON.parse(cachedData)['questionOne'], answerOne: JSON.parse(cachedData)['answerOne'], questionTwo: JSON.parse(cachedData)['questionTwo'], answerTwo: JSON.parse(cachedData)['answerTwo'], matrix: JSON.parse(cachedData2)});
      return;
    }*/
    
    
     const sheetsToData = async () => {
      try {
        await doc.useServiceAccountAuth({
          //key: "AIzaSyCZlmooivp6d9prrTllhZNlGfzjYeq0tx4"
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });

        // loads document properties and worksheets-
        await doc.loadInfo();

        var sheet = doc.sheetsById["14121067"];
        var row = sheet.getRows();
        
        var storiesMatrix = {};

        
        //rows for pages
        row.then((value) => {


          this.setState({
            questionOne: (String(value[0]['_rawData'][0])),
            answerOne: (String(value[0]['_rawData'][1])).toLowerCase(),
            questionTwo: (String(value[1]['_rawData'][0])),
            answerTwo: (String(value[1]['_rawData'][1])).toLowerCase(),
            /*questionThree: (String(value[2]['_rawData'][0])),
            answerThree: (String(value[2]['_rawData'][1])).toLowerCase()*/


          })

          


          localStorage.setItem("questionsData", JSON.stringify({
            questionOne: (String(value[0]['_rawData'][0])),
            answerOne: (String(value[0]['_rawData'][1])).toLowerCase(),
            questionTwo: (String(value[1]['_rawData'][0])),
            answerTwo: (String(value[1]['_rawData'][1])).toLowerCase(),

          }));
       
          
          /*console.log(this.state.questionOne)
          console.log(this.state.answerOne)
          console.log(this.state.questionTwo)
          console.log(this.state.answerTwo)
          console.log(this.state.questionThree)
          console.log(this.state.answerThree)*/
          })

        var sheet = doc.sheetsById["471573453"];
        var row = sheet.getRows();
        
        var storiesMatrix = {};

        
        //rows for pages
        row.then((value) => {

          
          for(var i = 0; i < value.length; i++)
          {
            var monthYearSheets = null;
            var dateSheets = new Date(String(value[i]['_rawData'][0]))
            var coverImageSheets = (String(value[i]['_rawData'][1]))
            var headerSheets = (String(value[i]['_rawData'][2]))
            var bodySheets = (String(value[i]['_rawData'][3])).split("\n")
            var imagesSheets = (value[i]['_rawData'][4]).split(',')
            monthYearSheets = (new Date(dateSheets.getFullYear(), dateSheets.getMonth()))


            


       
            //console.log(monthYearSheets.getMonth() + " " + monthYearSheets.getFullYear())
            if(monthYearSheets in storiesMatrix)
            {
              storiesMatrix[monthYearSheets] = storiesMatrix[monthYearSheets].concat([{date: dateSheets, coverImage: coverImageSheets, header: headerSheets, body: bodySheets, images: imagesSheets}])
            }
            else
            {
              storiesMatrix[monthYearSheets] = [{date: dateSheets, coverImage: coverImageSheets, header: headerSheets, body: bodySheets, images: imagesSheets}]
            }
          }
          //console.log(storiesMatrix)
          var dict=storiesMatrix
                    // Create items array
          var items = Object.keys(dict).map(function(key) {
            return [key, dict[key]];
          });

          // Sort the array based on the second element
          items.sort(function(first, second) {
            return second[1] - first[1];
          });

          // Create a new array with only the first 5 items
         storiesMatrix = items;

         this.setState({matrix: storiesMatrix})
         
         localStorage.setItem("blogData", JSON.stringify(storiesMatrix));
        
          
       
        })//.then((finalArray) => {this.setState({one: finalArray[0], two: finalArray[1], three: finalArray[2], four: finalArray[3], five: finalArray[4]})});
        
      } catch (e) {
          //error if google sheets quota reached
          alert("hmmm... the site failed to load, i'll reload automatically, exit this alert!" );
          
          setTimeout(() => {
            window.location.reload()
          }, 5000);
      }
    };

    sheetsToData()
  }

  

  

  
  render()
  
  /*NOTE- 
      stories matrix is a two fold array.
      0 index is MMDDYYYY
      1 index is array of stories in the dict form

      story dict 
      date, coverImage, header, body, images

  */

      

     
  {  
   
    return (
      <div className="secondaryPageContainer">
       <img src="https://dianamilena35mm.b-cdn.net/webp/assets/meowBackButton.webp" alt="back button" className='backButton' onClick={(e) => {window.location.href = "/"}} id="hideMe"></img>
       {this.state.showPage === false && <div className='centerMe'>
          <div className='formContainer'>
            <center>
            {/*<h3 style={{opacity: '.3'}}><b>answer the following questions<br></br> to enter the page:</b></h3>*/}

<div className='qs'>
            <svg viewBox="0 0 1132 596" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M976.83 507.07C976.83 507.07 956.44 491.77 954.52 493.05C952.61 494.33 915 501.97 915 501.97L920.74 595.66L967.27 589.29L976.83 507.07Z" fill="#F25369"/>
<path d="M929.847 546.588L938.971 547.943C939.353 550.758 940.413 552.809 942.151 554.095C944.48 555.833 947.66 556.702 951.691 556.702C956.036 556.702 959.39 555.833 961.753 554.095C964.117 552.357 965.715 549.924 966.55 546.796C967.036 544.885 967.262 540.87 967.227 534.753C963.126 539.584 958.017 542 951.9 542C944.288 542 938.397 539.254 934.227 533.763C930.056 528.271 927.971 521.685 927.971 514.004C927.971 508.721 928.926 503.855 930.838 499.407C932.75 494.923 935.513 491.465 939.127 489.032C942.777 486.599 947.052 485.383 951.952 485.383C958.486 485.383 963.873 488.024 968.114 493.307V486.634H976.768V534.493C976.768 543.112 975.882 549.212 974.109 552.792C972.371 556.406 969.591 559.256 965.768 561.342C961.979 563.427 957.305 564.47 951.744 564.47C945.14 564.47 939.805 562.975 935.739 559.986C931.672 557.032 929.708 552.566 929.847 546.588ZM937.615 513.326C937.615 520.59 939.058 525.891 941.942 529.227C944.827 532.564 948.442 534.232 952.786 534.232C957.096 534.232 960.711 532.581 963.63 529.279C966.55 525.943 968.009 520.729 968.009 513.639C968.009 506.862 966.497 501.753 963.474 498.312C960.485 494.871 956.87 493.151 952.63 493.151C948.459 493.151 944.914 494.854 941.995 498.26C939.075 501.631 937.615 506.653 937.615 513.326Z" fill="#353130"/>
<path d="M912.04 442.5L861 445.46L861.74 560.86C861.74 560.86 922.4 560.86 922.4 558.64C922.4 556.42 912.04 442.5 912.04 442.5Z" fill="#FABEB1"/>
<path d="M865.804 504.873C865.804 492.974 869.112 484.161 875.727 478.433C881.253 473.674 887.989 471.294 895.935 471.294C904.768 471.294 911.988 474.198 917.595 480.006C923.201 485.774 926.005 493.761 926.005 503.965C926.005 512.234 924.754 518.748 922.253 523.508C919.793 528.227 916.183 531.898 911.423 534.519C906.704 537.141 901.541 538.452 895.935 538.452C886.94 538.452 879.659 535.568 874.093 529.8C868.567 524.032 865.804 515.723 865.804 504.873ZM876.997 504.873C876.997 513.101 878.792 519.273 882.382 523.387C885.972 527.461 890.489 529.498 895.935 529.498C901.34 529.498 905.837 527.441 909.427 523.326C913.017 519.212 914.812 512.94 914.812 504.51C914.812 496.564 912.996 490.554 909.366 486.48C905.776 482.366 901.299 480.309 895.935 480.309C890.489 480.309 885.972 482.346 882.382 486.42C878.792 490.493 876.997 496.645 876.997 504.873Z" fill="#F03D50"/>
<path d="M842.24 425.354C839.04 425.814 784 434.334 784 434.334L800.77 551.634L870.37 539.724L842.24 425.354Z" fill="#F57656"/>
<path d="M810.415 517.505L799.421 440.642L809.592 439.187L819.289 506.98L857.144 501.565L858.441 510.635L810.415 517.505Z" fill="#353130"/>
<path d="M988.03 256.75L945.97 245.5L921 275.51L932.17 336.29L991.97 329.54L988.03 256.76" fill="#353130"/>
<path d="M931.065 295.423L940.373 293.958C940.896 297.689 942.343 300.547 944.714 302.535C947.119 304.522 950.466 305.515 954.754 305.515C959.077 305.515 962.285 304.644 964.376 302.901C966.468 301.123 967.514 299.048 967.514 296.678C967.514 294.551 966.59 292.877 964.743 291.657C963.453 290.821 960.245 289.757 955.12 288.467C948.217 286.724 943.424 285.225 940.739 283.97C938.09 282.68 936.067 280.919 934.673 278.688C933.313 276.422 932.633 273.929 932.633 271.21C932.633 268.735 933.191 266.451 934.307 264.359C935.457 262.233 937.009 260.472 938.961 259.078C940.425 257.997 942.413 257.09 944.923 256.358C947.468 255.591 950.187 255.208 953.081 255.208C957.439 255.208 961.256 255.835 964.533 257.09C967.845 258.345 970.286 260.054 971.855 262.215C973.423 264.342 974.504 267.201 975.097 270.792L965.893 272.047C965.475 269.188 964.254 266.957 962.232 265.353C960.245 263.749 957.421 262.947 953.761 262.947C949.438 262.947 946.352 263.662 944.504 265.091C942.657 266.521 941.733 268.194 941.733 270.112C941.733 271.332 942.116 272.43 942.883 273.406C943.65 274.417 944.853 275.254 946.492 275.917C947.433 276.265 950.205 277.067 954.806 278.322C961.465 280.1 966.102 281.564 968.717 282.715C971.367 283.83 973.441 285.469 974.94 287.631C976.439 289.792 977.189 292.477 977.189 295.684C977.189 298.822 976.265 301.785 974.417 304.574C972.604 307.328 969.972 309.472 966.521 311.006C963.069 312.506 959.164 313.255 954.806 313.255C947.59 313.255 942.081 311.756 938.281 308.758C934.516 305.759 932.11 301.314 931.065 295.423Z" fill="#D3B47E"/>
<path d="M1052.91 254.333L1014.69 233.475L983.343 256.744L979.855 318.444L1039.56 325.996L1052.9 254.342" fill="#FABEB1"/>
<path d="M988.424 278.47L997.815 279.244C997.443 282.992 998.175 286.111 1000.01 288.602C1001.88 291.101 1004.9 292.856 1009.06 293.868C1013.26 294.888 1016.59 294.798 1019.03 293.598C1021.48 292.364 1022.99 290.595 1023.55 288.291C1024.05 286.224 1023.55 284.38 1022.04 282.758C1020.98 281.641 1018.12 279.851 1013.44 277.388C1007.15 274.065 1002.84 271.477 1000.53 269.624C998.258 267.745 996.708 265.557 995.88 263.06C995.093 260.537 995.021 257.954 995.663 255.311C996.247 252.906 997.328 250.818 998.905 249.049C1000.53 247.254 1002.45 245.909 1004.67 245.015C1006.35 244.31 1008.5 243.898 1011.11 243.779C1013.76 243.634 1016.5 243.903 1019.31 244.586C1023.54 245.615 1027.11 247.125 1029.99 249.118C1032.92 251.12 1034.88 253.356 1035.9 255.826C1036.92 258.263 1037.3 261.296 1037.03 264.926L1027.79 263.973C1028.05 261.096 1027.39 258.64 1025.81 256.605C1024.26 254.577 1021.7 253.132 1018.14 252.268C1013.94 251.248 1010.78 251.214 1008.64 252.167C1006.51 253.12 1005.22 254.528 1004.76 256.391C1004.48 257.577 1004.59 258.735 1005.1 259.864C1005.61 261.028 1006.58 262.125 1008.02 263.155C1008.85 263.716 1011.36 265.149 1015.53 267.455C1021.58 270.754 1025.74 273.271 1028.01 275.006C1030.32 276.716 1031.95 278.798 1032.9 281.252C1033.85 283.706 1033.94 286.492 1033.18 289.609C1032.44 292.658 1030.85 295.319 1028.39 297.593C1025.98 299.842 1022.92 301.304 1019.2 301.981C1015.49 302.623 1011.52 302.43 1007.29 301.401C1000.27 299.698 995.275 296.942 992.29 293.131C989.339 289.329 988.05 284.442 988.424 278.47Z" fill="#353130"/>
<path d="M925.1 228.312L852.54 225.342L838 344.142L920.81 350.682L925.1 228.312Z" fill="#353130"/>
<path d="M857.064 318.428L867.607 221.383L937.776 229.005L936.532 240.458L879.205 234.23L875.976 263.952L929.662 269.785L928.425 281.171L874.739 275.338L871.151 308.371L930.728 314.843L929.484 326.295L857.064 318.428Z" fill="#F9AA3A"/>
<path d="M375.2 270.71L365 376.37L463.37 375.64L459 271.44L375.2 270.71Z" fill="#353130"/>
<path d="M371.081 358L404.636 270.627H417.092L452.852 358H439.68L429.489 331.538H392.954L383.358 358H371.081ZM396.292 322.121H425.913L416.794 297.924C414.013 290.573 411.946 284.534 410.596 279.805C409.483 285.408 407.914 290.97 405.887 296.493L396.292 322.121Z" fill="#EFE4CD"/>
<path d="M1053.98 103.58H989.77L989 163.15H1060.17L1053.98 103.58Z" fill="#D3B47E"/>
<path d="M1010.97 142V74.8005H1021.22V84.3552C1026.16 76.973 1033.29 73.2819 1042.61 73.2819C1046.66 73.2819 1050.37 74.0201 1053.75 75.4965C1057.16 76.9308 1059.72 78.8291 1061.4 81.1914C1063.09 83.5537 1064.27 86.359 1064.95 89.6072C1065.37 91.7164 1065.58 95.4075 1065.58 100.681V142H1054.19V101.123C1054.19 96.4832 1053.75 93.0241 1052.86 90.7461C1051.98 88.426 1050.39 86.591 1048.12 85.2411C1045.88 83.849 1043.24 83.153 1040.21 83.153C1035.35 83.153 1031.16 84.6927 1027.61 87.7722C1024.11 90.8516 1022.36 96.6941 1022.36 105.3V142H1010.97Z" fill="#353130"/>
<path d="M995.04 59.5L944 62.46L944.74 177.86C944.74 177.86 1005.4 177.86 1005.4 175.64C1005.4 173.42 995.04 59.5 995.04 59.5Z" fill="#FABEB1"/>
<path d="M948.804 121.873C948.804 109.974 952.112 101.161 958.727 95.4332C964.253 90.6736 970.989 88.2938 978.935 88.2938C987.768 88.2938 994.988 91.198 1000.59 97.0062C1006.2 102.774 1009 110.761 1009 120.965C1009 129.234 1007.75 135.748 1005.25 140.508C1002.79 145.227 999.183 148.898 994.423 151.519C989.704 154.141 984.541 155.452 978.935 155.452C969.94 155.452 962.659 152.568 957.093 146.8C951.567 141.032 948.804 132.723 948.804 121.873ZM959.997 121.873C959.997 130.101 961.792 136.273 965.382 140.387C968.972 144.461 973.489 146.498 978.935 146.498C984.34 146.498 988.837 144.441 992.427 140.326C996.017 136.212 997.812 129.94 997.812 121.51C997.812 113.564 995.996 107.554 992.366 103.48C988.776 99.3659 984.299 97.3088 978.935 97.3088C973.489 97.3088 968.972 99.3457 965.382 103.42C961.792 107.493 959.997 113.645 959.997 121.873Z" fill="#F03D50"/>
<path d="M844.03 102.75L801.97 91.5L777 121.51L788.17 182.29L847.97 175.54L844.03 102.76" fill="#353130"/>
<path d="M787.065 141.423L796.373 139.958C796.896 143.689 798.343 146.547 800.714 148.535C803.119 150.522 806.466 151.515 810.754 151.515C815.077 151.515 818.285 150.644 820.376 148.901C822.468 147.123 823.514 145.048 823.514 142.678C823.514 140.551 822.59 138.877 820.743 137.657C819.453 136.821 816.245 135.757 811.12 134.467C804.217 132.724 799.424 131.225 796.739 129.97C794.09 128.68 792.067 126.919 790.673 124.688C789.313 122.422 788.633 119.929 788.633 117.21C788.633 114.735 789.191 112.451 790.307 110.359C791.457 108.233 793.009 106.472 794.961 105.078C796.425 103.997 798.413 103.09 800.923 102.358C803.468 101.591 806.187 101.208 809.081 101.208C813.439 101.208 817.256 101.835 820.533 103.09C823.845 104.345 826.286 106.054 827.855 108.215C829.423 110.342 830.504 113.201 831.097 116.792L821.893 118.047C821.475 115.188 820.254 112.957 818.232 111.353C816.245 109.749 813.421 108.947 809.761 108.947C805.438 108.947 802.352 109.662 800.504 111.091C798.657 112.521 797.733 114.194 797.733 116.112C797.733 117.332 798.116 118.43 798.883 119.406C799.65 120.417 800.853 121.254 802.492 121.917C803.433 122.265 806.205 123.067 810.806 124.322C817.465 126.1 822.102 127.564 824.717 128.715C827.367 129.83 829.441 131.469 830.94 133.631C832.439 135.792 833.189 138.477 833.189 141.684C833.189 144.822 832.265 147.785 830.417 150.574C828.604 153.328 825.972 155.472 822.521 157.006C819.069 158.506 815.164 159.255 810.806 159.255C803.59 159.255 798.081 157.756 794.281 154.758C790.516 151.759 788.11 147.314 787.065 141.423Z" fill="#D3B47E"/>
<path d="M1128.03 90.75L1085.97 79.5L1061 109.51L1072.17 170.29L1131.97 163.54L1128.03 90.76" fill="#353130"/>
<path d="M1071.06 129.423L1080.37 127.958C1080.9 131.689 1082.34 134.547 1084.71 136.535C1087.12 138.522 1090.47 139.515 1094.75 139.515C1099.08 139.515 1102.28 138.644 1104.38 136.901C1106.47 135.123 1107.51 133.048 1107.51 130.678C1107.51 128.551 1106.59 126.877 1104.74 125.657C1103.45 124.821 1100.25 123.757 1095.12 122.467C1088.22 120.724 1083.42 119.225 1080.74 117.97C1078.09 116.68 1076.07 114.919 1074.67 112.688C1073.31 110.422 1072.63 107.929 1072.63 105.21C1072.63 102.735 1073.19 100.451 1074.31 98.3593C1075.46 96.2327 1077.01 94.4721 1078.96 93.0775C1080.43 91.9968 1082.41 91.0903 1084.92 90.3582C1087.47 89.5912 1090.19 89.2077 1093.08 89.2077C1097.44 89.2077 1101.26 89.8353 1104.53 91.0903C1107.85 92.3454 1110.29 94.0537 1111.85 96.2152C1113.42 98.3419 1114.5 101.201 1115.1 104.792L1105.89 106.047C1105.47 103.188 1104.25 100.957 1102.23 99.3529C1100.25 97.7492 1097.42 96.9474 1093.76 96.9474C1089.44 96.9474 1086.35 97.6621 1084.5 99.0915C1082.66 100.521 1081.73 102.194 1081.73 104.112C1081.73 105.332 1082.12 106.43 1082.88 107.406C1083.65 108.417 1084.85 109.254 1086.49 109.917C1087.43 110.265 1090.2 111.067 1094.81 112.322C1101.47 114.1 1106.1 115.564 1108.72 116.715C1111.37 117.83 1113.44 119.469 1114.94 121.631C1116.44 123.792 1117.19 126.477 1117.19 129.684C1117.19 132.822 1116.26 135.785 1114.42 138.574C1112.6 141.328 1109.97 143.472 1106.52 145.006C1103.07 146.506 1099.16 147.255 1094.81 147.255C1087.59 147.255 1082.08 145.756 1078.28 142.758C1074.52 139.759 1072.11 135.314 1071.06 129.423Z" fill="#D3B47E"/>
<path d="M777.1 54.3124L704.54 51.3423L690 170.142L772.81 176.682L777.1 54.3124Z" fill="#6578A7"/>
<path d="M709.064 144.428L719.607 47.3826L789.776 55.0054L788.532 66.4575L731.205 60.2298L727.976 89.9525L781.662 95.7847L780.425 107.171L726.739 101.338L723.151 134.371L782.728 140.843L781.484 152.295L709.064 144.428Z" fill="#F9AA3A"/>
<path d="M686.25 114.39C684.22 114.39 620 115.74 620 117.77C620 119.8 623.38 202.96 623.38 202.96L703.83 198.9L686.25 114.39Z" fill="#EFE4CD"/>
<path d="M690.924 100.933H701.652V147.771C701.652 155.918 700.73 162.388 698.887 167.18C697.043 171.973 693.707 175.88 688.878 178.903C684.085 181.889 677.781 183.382 669.966 183.382C662.372 183.382 656.16 182.074 651.331 179.456C646.501 176.839 643.054 173.06 640.99 168.12C638.925 163.143 637.893 156.36 637.893 147.771V100.933H648.621V147.715C648.621 154.757 649.266 159.955 650.556 163.309C651.884 166.627 654.132 169.189 657.303 170.996C660.51 172.802 664.418 173.705 669.026 173.705C676.915 173.705 682.537 171.917 685.892 168.341C689.246 164.766 690.924 157.89 690.924 147.715V100.933Z" fill="#353130"/>
<path d="M434.9 94.3999C434.9 94.3999 357.05 100.48 357.66 102.31C358.27 104.13 368.61 177.12 368.61 177.12L433.69 171.04L434.91 94.4099" fill="#6578A7"/>
<path d="M377.246 157.06V104.23H385.305V112.239C387.361 108.491 389.252 106.021 390.976 104.827C392.734 103.633 394.657 103.036 396.747 103.036C399.765 103.036 402.832 103.998 405.95 105.921L402.865 114.229C400.677 112.935 398.488 112.289 396.299 112.289C394.342 112.289 392.584 112.886 391.026 114.079C389.467 115.24 388.356 116.865 387.693 118.955C386.698 122.138 386.2 125.621 386.2 129.401V157.06H377.246Z" fill="#F9AA3A"/>
<path d="M363.98 38.1799L291.42 35.21L276.88 154.01L359.69 160.55L363.98 38.1799Z" fill="#353130"/>
<path d="M295.954 128.296L306.496 31.2502L376.666 38.8731L375.422 50.3252L318.095 44.0975L314.866 73.8202L368.552 79.6524L367.315 91.0383L313.629 85.2061L310.04 118.239L369.618 124.711L368.374 136.163L295.954 128.296Z" fill="#F9AA3A"/>
<path d="M636.1 426.312L563.54 423.342L549 542.142L631.81 548.682L636.1 426.312Z" fill="#353130"/>
<path d="M568.074 516.428L578.617 419.383L648.786 427.005L647.542 438.458L590.215 432.23L586.986 461.952L640.672 467.785L639.435 479.171L585.749 473.338L582.16 506.371L641.738 512.843L640.494 524.295L568.074 516.428Z" fill="#F57656"/>
<path d="M268.91 77.54L207.76 83.1L197.34 128.27L209.15 162.32L275.86 153.29C275.86 153.29 288.37 107.42 288.37 105.34C288.37 103.26 268.91 77.54 268.91 77.54Z" fill="#6578A7"/>
<path d="M233.265 131.98L214.791 71.615H225.364L234.97 106.458L238.551 119.418C238.702 118.774 239.744 114.625 241.677 106.97L251.283 71.615H261.799L270.836 106.629L273.849 118.168L277.316 106.515L287.661 71.615H297.608L278.737 131.98H268.108L258.502 95.8292L256.171 85.541L243.951 131.98H233.265Z" fill="#E3D0AC"/>
<path d="M10.2 37.96L0 143.62L98.37 142.89L94 38.6899L10.2 37.96Z" fill="#353130"/>
<path d="M6.08121 125.25L39.6358 37.877H52.0921L87.8519 125.25H74.6804L64.4888 98.7878H27.9543L18.3587 125.25H6.08121ZM31.2918 89.371H60.9129L51.7941 65.1736C49.0128 57.823 46.9467 51.7835 45.5958 47.0553C44.4832 52.6577 42.9138 58.2203 40.8874 63.7432L31.2918 89.371Z" fill="#EFE4CD"/>
<path d="M644.2 225.71L634 331.37L732.37 330.64L728 226.44L644.2 225.71Z" fill="#353130"/>
<path d="M640.081 313L673.636 225.627H686.092L721.852 313H708.68L698.489 286.538H661.954L652.358 313H640.081ZM665.292 277.121H694.913L685.794 252.924C683.013 245.573 680.946 239.534 679.596 234.805C678.483 240.408 676.914 245.97 674.887 251.493L665.292 277.121Z" fill="#EFE4CD"/>
<path d="M730 471.53L731.87 573.57L785.38 572.33L794.71 469.05L730 471.54" fill="#D3B47E"/>
<path d="M753.062 549H742.841V459.279H753.857V491.287C758.509 485.453 764.445 482.536 771.667 482.536C775.665 482.536 779.439 483.352 782.989 484.984C786.58 486.575 789.517 488.839 791.802 491.777C794.128 494.674 795.943 498.183 797.249 502.304C798.555 506.424 799.207 510.831 799.207 515.523C799.207 526.662 796.453 535.271 790.945 541.35C785.437 547.429 778.827 550.469 771.116 550.469C763.445 550.469 757.427 547.266 753.062 540.86V549ZM752.939 516.013C752.939 523.806 754 529.436 756.122 532.904C759.59 538.575 764.282 541.411 770.198 541.411C775.013 541.411 779.174 539.33 782.683 535.169C786.192 530.966 787.946 524.724 787.946 516.441C787.946 507.954 786.253 501.691 782.867 497.652C779.521 493.613 775.461 491.593 770.688 491.593C765.873 491.593 761.711 493.695 758.203 497.897C754.694 502.059 752.939 508.097 752.939 516.013Z" fill="#353130"/>
<path d="M730.001 273.479L724.581 340.109L791.621 350.648C791.621 350.648 801.341 277.159 799.501 276.859C797.661 276.549 730.001 273.488 730.001 273.488" fill="#EFE4CD"/>
<path d="M768.769 312.232L776.75 314.652C774.922 320.127 771.898 324.155 767.678 326.736C763.494 329.292 758.729 330.124 753.382 329.233C746.683 328.117 741.654 325.037 738.294 319.992C734.97 314.922 733.989 308.3 735.351 300.127C736.232 294.841 737.878 290.363 740.29 286.69C742.702 283.018 745.854 280.496 749.746 279.123C753.674 277.725 757.743 277.376 761.953 278.078C767.269 278.964 771.392 281.04 774.322 284.308C777.256 287.544 778.788 291.716 778.916 296.822L770.642 296.722C770.422 293.306 769.467 290.605 767.777 288.618C766.117 286.636 763.905 285.414 761.139 284.953C756.96 284.257 753.314 285.197 750.2 287.773C747.091 290.319 744.997 294.833 743.917 301.317C742.821 307.893 743.285 312.881 745.308 316.282C747.331 319.682 750.37 321.72 754.427 322.396C757.684 322.939 760.57 322.394 763.084 320.76C765.599 319.126 767.494 316.283 768.769 312.232Z" fill="#F03D50"/>
<path d="M775.446 259.916L785.916 325.941L853.549 320.349C853.549 320.349 845.637 246.643 843.778 246.786C841.917 246.919 775.449 259.926 775.449 259.926" fill="#6578A7"/>
<path d="M822.27 288.417L830.598 288.883C830.114 294.635 828.127 299.263 824.636 302.768C821.174 306.24 816.74 308.174 811.334 308.571C804.561 309.069 798.947 307.263 794.49 303.155C790.063 299.014 787.546 292.811 786.939 284.546C786.546 279.203 787.088 274.462 788.565 270.324C790.041 266.186 792.508 262.99 795.966 260.737C799.453 258.451 803.325 257.151 807.581 256.838C812.956 256.444 817.452 257.488 821.071 259.971C824.687 262.423 827.16 266.114 828.491 271.046L820.428 272.903C819.407 269.636 817.841 267.236 815.729 265.705C813.648 264.171 811.21 263.506 808.414 263.712C804.189 264.022 800.867 265.797 798.45 269.035C796.031 272.243 795.062 277.125 795.543 283.68C796.032 290.329 797.66 295.066 800.429 297.893C803.198 300.719 806.633 301.982 810.734 301.681C814.028 301.439 816.703 300.227 818.761 298.046C820.818 295.864 821.988 292.655 822.27 288.417Z" fill="#F9AA3A"/>
<path d="M386.83 248.07C386.83 248.07 366.44 232.77 364.52 234.05C362.61 235.33 325 242.97 325 242.97L330.74 336.66L377.27 330.29L386.83 248.07Z" fill="#F57656"/>
<path d="M332.318 287.588L341.441 288.943C341.823 291.758 342.883 293.809 344.621 295.095C346.95 296.833 350.13 297.702 354.162 297.702C358.506 297.702 361.86 296.833 364.224 295.095C366.587 293.357 368.186 290.924 369.02 287.796C369.506 285.885 369.732 281.87 369.698 275.753C365.596 280.584 360.487 283 354.37 283C346.759 283 340.868 280.254 336.697 274.763C332.526 269.271 330.441 262.685 330.441 255.004C330.441 249.721 331.397 244.855 333.308 240.407C335.22 235.923 337.983 232.465 341.597 230.032C345.247 227.599 349.522 226.383 354.422 226.383C360.956 226.383 366.344 229.024 370.584 234.307V227.634H379.238V275.493C379.238 284.112 378.352 290.212 376.579 293.792C374.841 297.406 372.061 300.256 368.238 302.342C364.449 304.427 359.775 305.47 354.214 305.47C347.61 305.47 342.275 303.975 338.209 300.986C334.142 298.032 332.179 293.566 332.318 287.588ZM340.086 254.326C340.086 261.59 341.528 266.891 344.413 270.227C347.297 273.564 350.912 275.232 355.257 275.232C359.566 275.232 363.181 273.581 366.1 270.279C369.02 266.943 370.48 261.729 370.48 254.639C370.48 247.862 368.968 242.753 365.944 239.312C362.955 235.871 359.34 234.151 355.1 234.151C350.929 234.151 347.384 235.854 344.465 239.26C341.545 242.631 340.086 247.653 340.086 254.326Z" fill="#353130"/>
<path d="M617.9 78.8201C617.9 78.8201 605.84 53.8101 600.67 48.8601C596.66 48.4101 540.74 44.3701 540.74 44.3701L531 141.75L550.48 172.46H615.65L623.89 143.25L617.9 78.8301" fill="#D3B47E"/>
<path d="M619.971 137.381C625.486 141.179 630.571 143.957 635.227 145.713L631.735 153.985C625.281 151.656 618.848 147.98 612.435 142.956C605.777 146.673 598.425 148.532 590.378 148.532C582.25 148.532 574.877 146.571 568.26 142.65C561.643 138.729 556.537 133.214 552.942 126.107C549.389 119 547.612 110.994 547.612 102.089C547.612 93.2258 549.409 85.1586 553.004 77.888C556.598 70.6173 561.704 65.0826 568.321 61.2839C574.979 57.4852 582.413 55.5859 590.623 55.5859C598.915 55.5859 606.39 57.5669 613.048 61.529C619.706 65.4503 624.771 70.9645 628.243 78.0718C631.755 85.1382 633.512 93.1237 633.512 102.028C633.512 109.421 632.389 116.079 630.142 122.002C627.895 127.884 624.505 133.01 619.971 137.381ZM593.809 122.186C600.671 124.106 606.329 126.965 610.781 130.764C617.766 124.392 621.258 114.813 621.258 102.028C621.258 94.7575 620.012 88.4059 617.52 82.9733C615.07 77.5408 611.455 73.3336 606.676 70.3518C601.938 67.3292 596.607 65.8179 590.684 65.8179C581.821 65.8179 574.468 68.8609 568.627 74.947C562.786 80.9923 559.866 90.0398 559.866 102.089C559.866 113.771 562.746 122.737 568.505 128.987C574.305 135.236 581.698 138.361 590.684 138.361C594.932 138.361 598.935 137.564 602.693 135.971C598.976 133.562 595.055 131.846 590.929 130.825L593.809 122.186Z" fill="#353130"/>
<path d="M213.85 43.75L171.79 32.5L146.82 62.51L157.99 123.29L217.79 116.54L213.85 43.76" fill="#353130"/>
<path d="M156.885 82.4225L166.193 80.9583C166.716 84.6886 168.163 87.5474 170.534 89.5346C172.939 91.5218 176.286 92.5154 180.575 92.5154C184.898 92.5154 188.105 91.6438 190.197 89.9007C192.289 88.1227 193.334 86.0483 193.334 83.6776C193.334 81.5509 192.411 79.8775 190.563 78.6573C189.273 77.8206 186.065 76.7572 180.941 75.4673C174.038 73.7241 169.244 72.225 166.559 70.9699C163.91 69.68 161.888 67.9194 160.493 65.6881C159.134 63.422 158.454 60.9293 158.454 58.21C158.454 55.7347 159.012 53.4511 160.127 51.3593C161.278 49.2327 162.829 47.4721 164.781 46.0775C166.246 44.9968 168.233 44.0903 170.743 43.3582C173.288 42.5912 176.007 42.2077 178.901 42.2077C183.259 42.2077 187.077 42.8353 190.354 44.0903C193.666 45.3454 196.106 47.0537 197.675 49.2152C199.244 51.3419 200.325 54.2007 200.917 57.7916L191.713 59.0467C191.295 56.1879 190.075 53.9566 188.053 52.3529C186.065 50.7492 183.242 49.9474 179.581 49.9474C175.258 49.9474 172.172 50.6621 170.325 52.0915C168.477 53.5209 167.553 55.1943 167.553 57.1118C167.553 58.332 167.937 59.4302 168.704 60.4063C169.471 61.4174 170.673 62.2541 172.312 62.9165C173.253 63.2651 176.025 64.067 180.627 65.3221C187.286 67.1001 191.923 68.5644 194.537 69.7148C197.187 70.8305 199.261 72.469 200.76 74.6306C202.259 76.7921 203.009 79.4766 203.009 82.684C203.009 85.8217 202.085 88.7851 200.237 91.5741C198.425 94.3283 195.792 96.4724 192.341 98.0064C188.889 99.5055 184.985 100.255 180.627 100.255C173.41 100.255 167.902 98.756 164.102 95.7577C160.336 92.7595 157.931 88.3144 156.885 82.4225Z" fill="#D3B47E"/>
<path d="M156.43 214.44C154.26 214.44 82 215.16 82 215.16L97.9 327.89L152.82 322.11L156.43 214.44Z" fill="#FABEB1"/>
<path d="M125.335 301V224.575H96.7871V214.35H165.469V224.575H136.802V301H125.335Z" fill="#F03D50"/>
<path d="M552.98 308.58H488.77L488 368.15H559.17L552.98 308.58Z" fill="#D3B47E"/>
<path d="M509.972 347V279.8H520.223V289.355C525.159 281.973 532.288 278.282 541.611 278.282C545.66 278.282 549.373 279.02 552.747 280.497C556.164 281.931 558.716 283.829 560.404 286.191C562.091 288.554 563.272 291.359 563.947 294.607C564.369 296.716 564.58 300.408 564.58 305.681V347H553.19V306.123C553.19 301.483 552.747 298.024 551.861 295.746C550.976 293.426 549.394 291.591 547.116 290.241C544.88 288.849 542.243 288.153 539.206 288.153C534.355 288.153 530.158 289.693 526.614 292.772C523.113 295.852 521.362 301.694 521.362 310.3V347H509.972Z" fill="#353130"/>
<path d="M153.45 97.8701H89.24L88.47 157.44H159.64L153.45 97.8701Z" fill="#D3B47E"/>
<path d="M110.452 136.29V69.0905H120.703V78.6453C125.639 71.263 132.768 67.5719 142.09 67.5719C146.14 67.5719 149.852 68.3101 153.227 69.7866C156.644 71.2208 159.196 73.1191 160.883 75.4815C162.571 77.8438 163.752 80.649 164.427 83.8972C164.849 86.0064 165.06 89.6975 165.06 94.9706V136.29H153.67V95.4135C153.67 90.7732 153.227 87.3141 152.341 85.0362C151.455 82.716 149.873 80.881 147.595 79.5311C145.36 78.1391 142.723 77.443 139.686 77.443C134.835 77.443 130.637 78.9827 127.094 82.0622C123.593 85.1416 121.842 90.9842 121.842 99.5897V136.29H110.452Z" fill="#353130"/>
<path d="M902.43 55.4399C900.26 55.4399 828 56.1599 828 56.1599L843.9 168.89L898.82 163.11L902.43 55.4399Z" fill="#FABEB1"/>
<path d="M871.345 142V65.5754H842.797V55.3499H911.479V65.5754H882.812V142H871.345Z" fill="#F03D50"/>
<path d="M282.43 443.44C280.26 443.44 208 444.16 208 444.16L223.9 556.89L278.82 551.11L282.43 443.44Z" fill="#FABEB1"/>
<path d="M251.345 530V453.575H222.797V443.35H291.479V453.575H262.812V530H251.345Z" fill="#F03D50"/>
<path d="M492.43 442.44C490.26 442.44 418 443.16 418 443.16L433.9 555.89L488.82 550.11L492.43 442.44Z" fill="#6578A7"/>
<path d="M461.345 529V452.575H432.797V442.35H501.479V452.575H472.812V529H461.345Z" fill="#F9AA3A"/>
<path d="M322.04 470.51L271 473.47L271.74 588.87C271.74 588.87 332.4 588.87 332.4 586.65C332.4 584.43 322.04 470.51 322.04 470.51Z" fill="black"/>
<path d="M275.804 532.873C275.804 520.974 279.112 512.161 285.727 506.433C291.253 501.674 297.989 499.294 305.935 499.294C314.768 499.294 321.988 502.198 327.595 508.006C333.201 513.774 336.005 521.761 336.005 531.965C336.005 540.234 334.754 546.748 332.253 551.508C329.793 556.227 326.183 559.898 321.423 562.519C316.704 565.141 311.541 566.452 305.935 566.452C296.94 566.452 289.659 563.568 284.093 557.8C278.567 552.032 275.804 543.723 275.804 532.873ZM286.997 532.873C286.997 541.101 288.792 547.273 292.382 551.387C295.972 555.461 300.489 557.498 305.935 557.498C311.34 557.498 315.837 555.441 319.427 551.326C323.017 547.212 324.812 540.94 324.812 532.51C324.812 524.564 322.996 518.554 319.366 514.48C315.776 510.366 311.299 508.309 305.935 508.309C300.489 508.309 295.972 510.346 292.382 514.42C288.792 518.493 286.997 524.645 286.997 532.873Z" fill="#EFE4CD"/>
<path d="M203.04 233.51L152 236.47L152.74 351.87C152.74 351.87 213.4 351.87 213.4 349.65C213.4 347.43 203.04 233.51 203.04 233.51Z" fill="#D3B47E"/>
<path d="M156.804 295.873C156.804 283.974 160.112 275.161 166.727 269.433C172.253 264.674 178.989 262.294 186.935 262.294C195.768 262.294 202.988 265.198 208.595 271.006C214.201 276.774 217.005 284.761 217.005 294.965C217.005 303.234 215.754 309.748 213.253 314.508C210.793 319.227 207.183 322.898 202.423 325.519C197.704 328.141 192.541 329.452 186.935 329.452C177.94 329.452 170.659 326.568 165.093 320.8C159.567 315.032 156.804 306.723 156.804 295.873ZM167.997 295.873C167.997 304.101 169.792 310.273 173.382 314.387C176.972 318.461 181.489 320.498 186.935 320.498C192.34 320.498 196.837 318.441 200.427 314.326C204.017 310.212 205.812 303.94 205.812 295.51C205.812 287.564 203.996 281.554 200.366 277.48C196.776 273.366 192.299 271.309 186.935 271.309C181.489 271.309 176.972 273.346 173.382 277.42C169.792 281.493 167.997 287.645 167.997 295.873Z" fill="#6578A7"/>
<path d="M890.09 90C890.09 92.09 888 178.64 888 178.64C888 178.64 936.16 183.53 936.16 180.73C936.16 177.93 949.42 100.47 949.42 100.47L890.1 90" fill="#F9AA3A"/>
<path d="M913.123 84.13V72.3134H923.399V84.13H913.123ZM913.123 156V95.3758H923.399V156H913.123Z" fill="#353130"/>
<path d="M452.09 237C452.09 239.09 450 325.64 450 325.64C450 325.64 498.16 330.53 498.16 327.73C498.16 324.93 511.42 247.47 511.42 247.47L452.1 237" fill="#F9AA3A"/>
<path d="M475.123 231.13V219.313H485.399V231.13H475.123ZM475.123 303V242.376H485.399V303H475.123Z" fill="#353130"/>
<path d="M480.01 476.37C480.67 478.35 484.62 570.58 484.62 570.58L568.95 565.97L560.38 467.8L480 476.37" fill="#F03D50"/>
<path d="M497.095 553V473.995H506.796V502.342C511.323 497.096 517.035 494.474 523.933 494.474C528.173 494.474 531.855 495.318 534.981 497.007C538.107 498.659 540.334 500.959 541.664 503.905C543.029 506.851 543.711 511.126 543.711 516.731V553H534.011V516.731C534.011 511.881 532.951 508.36 530.831 506.168C528.748 503.941 525.783 502.827 521.939 502.827C519.065 502.827 516.352 503.581 513.802 505.09C511.287 506.563 509.49 508.575 508.412 511.126C507.335 513.677 506.796 517.198 506.796 521.689V553H497.095Z" fill="#FDDDD6"/>
</svg>
</div>
<div className='spacing'></div>

            <div className='spacing'></div>
            <p>{this.state.questionOne}</p>
            <div className='spacing'></div>
            <div className='messageContainer'>
              <img src="https://dianamilena35mm.b-cdn.net/webp/assets/namebox%20(1).webp" className='qaBox' alt="namebox"></img>
              <div className='qaDiv'>
                <input className='qa-block' id="firstAnswer" placeholder='answer'></input>
              </div>
            </div>
            <div className='spacing'></div>

            <p>{this.state.questionTwo}</p>
            <div className='spacing'></div>
            <div className='messageContainer'>
              <img src="https://dianamilena35mm.b-cdn.net/webp/assets/namebox%20(1).webp" className='qaBox' alt="namebox"></img>
              <div className='qaDiv'>
                <input className='qa-block' id="secondAnswer" placeholder='answer'></input>
              </div>
            </div>
            <div className='spacing'></div>

           {/* <p>{this.state.questionThree}</p>
            <div className='spacing'></div>
            <div className='messageContainer'>
              <img src="https://dianamilena35mm.b-cdn.net/webp/assets/namebox%20(1).webp" className='qaBox' alt="namebox"></img>
              <div className='qaDiv'>
                <input className='qa-block' id="thirdAnswer" placeholder='name'></input>
              </div>
    </div>


    <div className='spacing'></div>*/}
            <h2 className='submitText' onClick={this.submitMessage}>Submit</h2>
            </center>
          </div>
        </div>}

        {this.state.showPage === true && <div>
        
        <div className='blogContainer'>
            {this.state.matrix.map((month,i) => <div>
              <center><h1 onClick={(e) => {alert("helo")}}>{monthNames[new Date(month[0]).getMonth()] + " " + new Date(month[0]).getFullYear()}</h1></center>
              <br></br>
              {month[1].map((story, i) => <div> 
                <div className='coverImage' onClick={(e) => {this.handleOpenModal(story)}}>
                <img src={story.coverImage} className="blurMe" alt={story.coverImage}></img>
                <center><h1 className='coverImageHeader'>{story.header}</h1></center>

                </div>
                <br></br>
                <br></br>
        
                </div>)}
            
                </div>)}
          </div>

       
       


          {/*}
          
          date, coverImage, header, body, images*/}
          <ReactModal 
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              style={{
                overlay: {
                  backgroundColor: '#85cddf',
                  inset: '0px'
                },
                content: {
                  inset: '0px'
                },
              }}
            
            
            >
            <img src="https://dianamilena35mm.b-cdn.net/webp/assets/meowBackButton.webp" alt="back button" className='specialBackButton' onClick={(e) => {this.handleCloseModal()}}></img>
            <div className='blogContainer'>
            <div className='coverImage'>
              <h1 className="storyTitle">{this.state.currentStory.header}</h1>
              <h2 className="dateTitle">{monthNames[new Date(this.state.currentStory.date).getMonth()] + " " + new Date(this.state.currentStory.date).getDay() + ", " + new Date(this.state.currentStory.date).getFullYear()}</h2>
              {<p className='story'>
                <br></br>
                {this.state.currentStory.body === undefined ? '' : (this.state.currentStory.body).map((line, i) => <div>{line}<br></br></div>)}
              </p>}
              {/*(this.state.currentStory.body).map((line, i) => <div>hi</div>)*/}
              

              {(this.state.currentStory.images).map((image, i) => <div>
                <img src={image} alt={image}></img>
                <br></br>
                <br></br>
                </div>)}

                </div>
              </div>

          
              </ReactModal>
              </div>}
       </div>
 

    )
    
    
          
      
  }
}
export default Blog;