import React from 'react';
import './Home.css';
import { GoogleSpreadsheet } from "google-spreadsheet";
import ReactModal from 'react-modal';
import YouTube from 'react-youtube';




//const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"

const SPREADSHEET_ID = "1jsMkRwrqKNoL1gNX1vjxUFCTMJ8gCTHBVSOSTHdxqf0";
const CLIENT_EMAIL = "dianamilena35mm-admin@dianamilena35mm.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2fcbAiD4SXvqu\n5dP5GVq73GvROBGHQ2rK7n+PoLjrHJMinNClugMbcBjKftHR+CZxYZ1PTYQk+Ia6\n/SxPZ9FBg0K65IeReeqUyy+aQqUIfO8UnAfgxTG8MlrdXQbn/+Dp6w9aktWG9lxD\nb6DGhqUZUOB6GdH3LRjTKMq0g8eVYeVtkhKoudf3eMfiVCbMDgan5lPEPa0Ov/Bb\nLaaYRUV0cULLZ1yDbkFpcxkSjrz77ox044KjECkPHfVRpKGsMuIcMb1bc3uO9nco\nk3o1FP2B4gTvBNAP+MJBWzsQKAGCFCWgMMCjR/KsSQcgUddVpJbUel6wyxfQQE8Q\nkXYq9FpBAgMBAAECggEAI5fzoJDSPoYM8fj8t6y00EhlNRjw/kdteGg9NBfeiWj4\n37rjTTezLHJYO4KxzPjQErQ92P5fBFD/AlCoCFLQ8yy1Yrt5H6w+T0g0ICvYgYfQ\nn93xAWuk0DaoF13yH2T+itFgnMNf52N8ymBm5OHVKNNn/Ri1JbdDjMf7hWrgjieG\nJdeHAwsskQBiupPEB8uIFSlThjhKMbQRPjkG+oD9zUmOpAMkxIH3J3qCudsf64rm\nanjPiQeCyh0xhfHBZax46bEVJI2G+U/3Z+dJtZYjXx0RgR8IPBQwvVVTO426qVmk\n4/vfieIKDkqtKUFWBFEXXYYVFKGjMAh1IhvH+GqlmwKBgQDY0qousS0GW/kl85mw\n/rCFKNOjNr/rem97Xjs9z5gIKeVIJCmy4JdTZf30R+n8ud9IjCWUxmFPYoJQIK5L\nXpWQaAAK7T47eGKbJ0+N/QRZqSyvSBS3+Hq/SyMYfRuJCAVoIOY1VJoyCFKAjCdq\niZ5F6m1EjSd9lpqZ6yJGPpXGJwKBgQDXdxLFpOSyXHadmOvQw+5dyKAZakbc4wbT\nz9mPskv11oLO2h1fdi8RJxU/nvTx8+BXid4ffjui6rknItt+W/8uZG9nmHE96I5M\nJA8bt2mDKrOesCt1SXZI3ey9m9n8vvxXGsxr2WWVxncsKqGpnfg0AJ+Cm5a1W98F\ny5ArtB7FVwKBgQDYYFOJSTL/l6Vp5FJKOcD7AiVCSg749jRuzzkblCHKc9TmS4B5\njHw3tB7dOy8dEHjtMz8F23uc91fIkvSdLoTfmYoBXYSvYJI29+ICdq9ysstvGOXk\nOkp0wI0DkUZ7V8/dfIeATS+cIoYC1ts3DKMwbqQgtyTjxfn/BJItu6NIHQKBgEwf\ng4BUe4AQbs/LITVGo8Z6hSW1iIetpdHbky/TfEbEnL5cDRiW9pYGytkJXdzFnrMO\nwG07+fHbH8MV65o/Am7viPR+xTNsICoCa1wNdreitIcSEqiDZcjkrylmEKPdDeXw\nT9yil+NMlPoDrc3xQ6mLts88DQjSBn9TpgHqxxxNAoGAYaZcWFvAbvgVBk4W6tNU\nwdYwc++2u7FxuW/DWWRMohdt/YSuZqVZGK69kR/kgcnt40FxlzpAa9eiv+jcFdsJ\ncpTr2B39I3gioh7LMIv0Ubjv4nf7eoCduisbdi/VDMPCELBvEaKhfobfIILeT7dn\nJQ9Swbh3zKjT7qRM2+F20Pk=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

class Portfolio extends React.Component { 

  constructor() {
    super();
    this.state = { 
     mobile: false,
     imageArray: [],
     showModal: false,
     activeImg: null,
     currentIndex: null,
     aboutText: null,
     publications: [],
     lookBooks: [],
     pressAndMediaResume: null,
     pressAndMediaImg: null,
     eventsResume: null,
     eventsImg: null,
     type: null,
     content: null,
     link: null,
     youtubeVid: null,

     
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
  }

  handleOpenModal (typeOf, item) {

    this.setState({ showModal: true, type: typeOf, content: item.eventImages, link: item.website, youtubeVid: item.youtubeVid});
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  componentDidMount()
  {

    document.body.style.overflow = "scroll"

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({mobile: true});
    }  

    const cachedData = localStorage.getItem("aboutTextData");
    const cachedData2 = localStorage.getItem("resumeData");
    const cachedData3 = localStorage.getItem("lookbooksData");

   /* if (cachedData && cachedData2 && cachedData3) {
      this.setState({ aboutText: JSON.parse(cachedData) , eventsResume: JSON.parse(cachedData2)['eventsResume'], eventsImg: JSON.parse(cachedData2)['eventsImg'], lookBooks: JSON.parse(cachedData3)});
      return;
    }*/
    
     const sheetsToData = async () => {
      try {
        await doc.useServiceAccountAuth({
          //key: "AIzaSyCZlmooivp6d9prrTllhZNlGfzjYeq0tx4"
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });

        // loads document properties and worksheets-
        await doc.loadInfo();

        var sheet = doc.sheetsById["137862388"];
        var row = sheet.getRows();
        
        var tempArray = []
        //rows for pages
        row.then((value) => {
          for(var i = 0; i < value.length; i++)
          {
            tempArray.push(String(value[i]['_rawData'][0]))
          }
     
          this.setState({aboutText: tempArray[0].split("\n")})
          localStorage.setItem("aboutTextData", JSON.stringify(tempArray[0].split("\n")));

          //this.setState({imageArray: tempArray})
        }).then(() => {tempArray = []});
        
       /* sheet = doc.sheetsById["516321109"];
        row = sheet.getRows();
        
        var tempArray2 = []
        var tempDict = {}
        //rows for pages
        row.then((value) => {
          for(var i = 0; i < value.length; i++)
          {
            tempDict = {nameOfPub: String(value[i]['_rawData'][0]), linkToPub: String(value[i]['_rawData'][1])}
            tempArray2.push(tempDict)
          }

          this.setState({publications: tempArray2})
          localStorage.setItem("publicationsData", JSON.stringify(tempArray2));
        }).then(() => {tempArray2 = []});*/

        sheet = doc.sheetsById["728238087"];
        row = sheet.getRows();
        
        var tempArray3 = []
        var tempDict = {}
        //rows for pages
        row.then((value) => {
          for(var i = 0; i < value.length; i++)
          {
            tempDict = {eventName: String(value[i]['_rawData'][0]), eventImages: (value[i]['_rawData'][1]).split(','), website: String(value[i]['_rawData'][2]), youtubeVid: String(value[i]['_rawData'][3])}
            tempArray3.push(tempDict)
          }

          this.setState({lookBooks: tempArray3})
          localStorage.setItem("lookbooksData", JSON.stringify(tempArray3));
        }).then(() => {tempArray3 = []});

        sheet = doc.sheetsById["1410373420"];
        row = sheet.getRows();
        
  
        var tempDict = {}
        //rows for pages
        row.then((value) => {
          /*for(var i = 0; i < value.length; i++)
          {
            tempDict = {eventName: String(value[i]['_rawData'][0]), eventImages: (value[i]['_rawData'][1]).split(',')}
            tempArray3.push(tempDict)
          }*/
          
          this.setState({pressAndMediaResume: String(value[0]['_rawData'][0]), pressAndMediaImg: String(value[0]['_rawData'][1]), eventsResume: String(value[1]['_rawData'][0]), eventsImg: String(value[1]['_rawData'][1])})
          localStorage.setItem("resumeData", JSON.stringify({eventsResume: String(value[1]['_rawData'][0]), eventsImg: String(value[1]['_rawData'][1])}));

          
          
          //localStorage.setItem("portfolioData", JSON.stringify(tempArray));
        }).then(() => {tempArray3 = []});



      } catch (e) {
          //error if google sheets quota reached
          alert("hmmm... the site failed to load, i'll reload automatically, exit this alert!" );
          
          setTimeout(() => {
            window.location.reload()
          }, 5000);
      }
    };

    sheetsToData()
   
  }

  //for the modal write a function where you pass in a type, and a data obj, either string or array 

  

  
  render()
  {  
    return (
      <div className="secondaryPageContainer" onClick={
        (e) => {
          
          if((e.target.className === "imageContainer") || (e.target.className === "ReactModal__Overlay ReactModal__Overlay--after-open") || (e.target.className === "ReactModal__Content ReactModal__Content--after-open"))
          {
            
            this.handleCloseModal()
          }
          
        }}>
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="Minimal Modal Example"
           style={{
            overlay: {
              zIndex: '999'
            },
            content: {
              zIndex: '999'
            },}}
         
        >
       
       
          <div className='blogContainer'>
        <div className='coverImage'>
          <center>
            {this.state.link === 'undefined' || this.state.link === null ? '' : <a href={this.state.link} target="_blank" rel="noreferrer"><h2>{this.state.link.split("https://")}</h2>
            </a>}

            <br></br>
            
            {this.state.youtubeVid === 'undefined' || this.state.link === null ? '' :<div> <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 25,
                  height: 0,
                  
                }}>
                  
                  
                  <iframe
                    style={{
                      position: "absolute",
                      top: '50%',
                      left: '50%',
                      width: "100%",
                      height: "100%",
                      alignSelf: 'center',
                      backgroundColor: 'transparent',
                    
                      transform: 'translate(-50%, -50%)'
                    }}
                    src={`https://www.youtube.com/embed/${this.state.youtubeVid}`}
                    frameBorder="0"
                  />
                  
                  </div>  <br></br>
            </div>}
           
            </center>

           
          {this.state.type === 'lookBook' && this.state.content.map(i => <div>
            <img loading='lazy' src={i} style={{marginBottom: '1em'}}></img>
            
          </div>) }
          </div>
          </div>
              {/*this.state.type === 'resume' && <iframe src={this.state.content + "#zoom=50"} className="resume"></iframe>*/}
         
         
              </ReactModal>
       <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/meowBackButton.webp" className='backButton' alt="back button" onClick={(e) => {window.location.href = "/"}}></img>
      
       <div className='resumeContainer'>
        <center>
          <h1>Diana Milena</h1>
          <h3>dianamilena35mm@gmail.com</h3>
          </center>
          <br></br>
          <div className='heroOne'>
            <div className='resHeroItem' style={{display: 'none'}}>
              <center>
                <h2>Press and Media</h2>

                <div className='overlay' onClick={(e) => {window.open(this.state.pressAndMediaResume, "_blank")}}>
                <div className='coverImage'>
                  <img loading='lazy' src={this.state.pressAndMediaImg} className="blurMe" alt="press and media resume" ></img>
   
                  <center><h1 className='coverImageHeader'>open</h1></center>
                </div>
                </div>
                
                {/*<div class="overlay"><img src="https://dianamilena35mm.b-cdn.net/webp/assets/pressAndMedia.webp" alt="press and media resume" className="resumeImage"></img>

    <center><h1 className='coverImageHeader'>open</h1></center>
    </div>*/}
              </center>
            </div>
            <div style={{width: '100%'}}>
            <div className='resHeroItem'>
              <center>
                <h2>Events</h2>

                <div className='overlay' onClick={(e) => {window.open(this.state.eventsResume, "_blank")}}>
                <div className='coverImage' >
                  <img loading='lazy' src={this.state.eventsImg} className="blurMe" alt="events resume"></img>
   
                  <center><h1 className='coverImageHeader'>open</h1></center>
                </div>
                </div>


                
              </center>
            </div>
            </div>
            
            <div className='heroItem' style={{display: 'none'}}>
       
              <center>
                <h2>Publications</h2>
                {this.state.publications.map((i) => <div style={{marginTop: '.7em'}}>

               
                <a href={i.linkToPub} target="_blank" rel="noreferrer"><p>{i.nameOfPub}</p></a>
                </div>
                )}
                
    
                
                
               
              </center>
            </div>
            
            <div style={{width: '100%', marginTop: '2rem'}}>
            <div className='heroItem'>
            
              <center>
                <h2>Look Books</h2>
             
                {this.state.lookBooks.map((i) => 
                <div style={{marginTop: '.7em'}}>
                  <a onClick={(e) => {this.handleOpenModal("lookBook", i)}} style={{textDecoration: 'underline', color: 'blue'}}>{i.eventName}</a>
                  
                 </div>
                )}

             
               
              </center>
            </div>
            </div>
            <div className='abtHeroItem' style={{marginTop: '1rem', marginBottom: '1rem'}}>
              <center>
                <h1>About</h1>
             
                
              </center>
              <p style={{textAlign: 'left'}}>{this.state.aboutText === null || this.state.aboutText === undefined ? '' : this.state.aboutText.map((line, i) => <div>{line}<br></br></div>)}</p>
            </div>
            <div className='abtHeroItem' style={{marginBottom: '0em !important'}}>
              <center>
    
                <img loading='lazy' src="https://dianamilena35mm.b-cdn.net/webp/assets/yell.webp" alt="yelling diana" className='dianaYell'></img>
      
              </center>
            </div>
            
            </div>
       
        

            
       
       </div>
       



       
       </div>
 

    )
    
    
          
      
  }
}
export default Portfolio;